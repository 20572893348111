import {Injectable} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {FileConvertService} from './file-convert.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private apiService: ApiService) {
  }

  imageUpload(postData: any, format: any) {
    const formData = new FormData();
    formData.append('image', postData, format);
    return this.apiService.post('/upload/image',formData).pipe(
      map(res => res.image)
    );
  }

  public async addNewToGallery(useCamera = false) {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: useCamera ? CameraSource.Camera : CameraSource.Photos,
      quality: 100
    });
    return capturedPhoto;
  }


}
