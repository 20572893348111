import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import {environment} from 'environments/environment';

@Injectable({
  providedIn: "root"
})


export  class AutologinService {
  constructor(private _apiService : ApiService) {
  }


  verifyToken(params:any):Promise<any>{
     return this._apiService.post('/login/auto-login-verify', ApiService.createFormData(params)).toPromise();
  }
}
