// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-list {
  display: flex;
  margin-top: 20px;
}

.folder-wrapper {
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  padding: 14px 20px;
  flex-basis: 49%;
}

.folder-icon {
  width: 52px;
  height: 52px;
}

.folder-name {
  width: 90px;
}

.folder-title {
  margin-top: 10px;
  width: 112px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/template-folder-list-skeleton/template-folder-list-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;;AACA;EACE,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;AAGF;;AADA;EACE,WAAA;AAIF;;AAFA;EACE,gBAAA;EACA,YAAA;AAKF","sourcesContent":[".folder-list{\n  display: flex;\n  margin-top:20px;\n}\n.folder-wrapper{\n  border:1px solid #C5C5C7;\n  border-radius: 8px;\n  padding: 14px 20px;\n  flex-basis:49%;\n}\n.folder-icon{\n  width: 52px;\n  height:52px;\n}\n.folder-name{\n  width:90px\n}\n.folder-title{\n  margin-top: 10px;\n  width: 112px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
