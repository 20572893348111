import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {IsAuthenticated, IsNotAuthenticated} from 'app/core/guards';
import {MainComponent, NoPageComponent} from 'app/shared/components';
import {AutoLoginComponent} from "./pages/authentication/components/auto-login/auto-login.component";

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [IsAuthenticated],
        children: [
            {
                path: 'tabs',
                loadChildren: () => import('app/tabs/tabs.module').then(m => m.TabsPageModule)
            },
            {
                path: 'nyhed-tilbud',
                loadChildren: () => import('app/pages/nyhed-tilbud/nyhed-tilbud.module').then(m => m.NyhedTilbudModule)
            },
            {
                path: 'personlig-hilsen',
                loadChildren: () => import('app/features/sms-klub/sms-klub.module').then(m => m.SmsKlubModule),
            },
            {
                path: '',
                redirectTo: 'tabs',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'login',
        loadChildren: () => import('app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    },
    {
        path: 'verify',
        component: AutoLoginComponent
    },
    {
        path: '**',
        component: NoPageComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
