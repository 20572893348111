import {State, Action, StateContext, Selector} from "@ngxs/store";
import {TabsAction} from "../actions/tabs.action";

@State<any>({
  name: "TabStates",
  defaults: {
    data: null
  }
})

export class TabsState {
  @Selector()
  static getData(state: any) {
    return state.data
  }

  @Action(TabsAction.SetTab)
  setData(ctx: StateContext<any>, action: TabsAction.SetTab) {
    ctx.patchState({data: action.tab})
  }

  @Action(TabsAction.ClearTabState)
  clearData(ctx: StateContext<any>) {
    const defaults = {
      data: null
    }
    ctx.setState(defaults)
  }
}
