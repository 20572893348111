// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  mainUrl: 'https://qsapidev.quickinfo.dk',
  apiUrl: 'https://qsapidev.quickinfo.dk/api',
  apiBaseUrl : 'https://qsapidev.quickinfo.dk',
  testUrl:'https://qijatakdev.quickinfo.dk/api/quickinfo',
  fbUrl: 'https://qijatakdev.quickinfo.dk/quickinfo_fblogin/',
  jatakUrl: 'https://jatakdev.quickinfo.dk/api/',
  appversion: require('../../package.json').version + '-dev',
};

// export const environment = {
//   production: false,
//   mainUrl: 'https://stagingadmin.quickinfo.dk',
//   apiUrl: 'https://stagingadmin.quickinfo.dk/api/quickinfo/',
//   fbUrl: 'https://stagingadmin.quickinfo.dk/quickinfo_fblogin/',
//   jatakUrl: 'https://jatak.quickinfo.dk/api/',
//   version: '3.2.5'
// };
//  apiUrl: 'https://qijatakdev.quickinfo.dk/api/quickinfo/',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
