import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss'],
})
export class OfferPreviewComponent implements OnInit {
  @Input() formData: any
  userplaceholderImg = "assets/image/user-dummy.jpg"
  @Output() showPreview: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  closePreview() {
    this.showPreview.emit(false)
  }


}
