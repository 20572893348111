import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthStateService, TokenService} from 'app/core/services';

@Injectable({
    providedIn: 'root'
})
export class PremiumGuard {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (TokenService.isPremium) {
            return true;
        }
        // this.router.navigate(['/']).then();
        // return false;
        return true
    }
}

@Injectable({
    providedIn: 'root'
})
class AuthenticationGuard {
    constructor(private router: Router) {
    }

    async isAuthenticated() {
        const token = await AuthStateService.getToken();
        if (token) {
            return true
        }
        this.router.navigate(['/login']).then();
        return false
    }

    async isNotAuthenticated() {
        const token = await AuthStateService.getToken();
        if (!token) {
            return true
        }
        this.router.navigate(['/']).then();
        return false
    }

}

export const IsAuthenticated: CanActivateFn = async () => {
    return await inject(AuthenticationGuard).isAuthenticated();
}

export const IsNotAuthenticated: CanActivateFn = async () => {
    return await inject(AuthenticationGuard).isNotAuthenticated();
}
