import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {JatakService} from "../../../features/jatak/services";
import {take} from "rxjs/operators";
import {ErrorHandlerService} from "../../../core/services";
import {TemplateData} from "../../interface";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit {
  @ViewChild('searchBox') searchBox: any;
  pageTitle: string = 'Template List'
  isInputfieldClicked = false;
  searchString = '';
  isTemplateLoading = true;
  placeholderImageUrl = "./assets/images/image89.svg"
  imageLoaded = false;
  showBackArrow: boolean = true
  folderType = '';
  folderId: any;
  templateList: TemplateData[] = []
  page = 1;
  last_page: number = 0;
  isSearchClicked = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _service: JatakService,
    private _route: Router,
    private _errorHandler: ErrorHandlerService,
    private _translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.folderType = this._activatedRoute.snapshot.data['assign'];
    this.folderId = this._activatedRoute.snapshot.params['id'];
    this.initComp()
  }

  private initComp() {
    if (this.folderType === 'templates') {
      this.getTemplateList()
    }
    if (this.folderType === 'saved' || this.folderType === 'assigned') {
      this.getOwnTemplates()
      this.pageTitle = this.folderType === 'saved' ? this._translateService.instant('_saved_templates_') : this._translateService.instant('_assigned_templates_')
    }
  }

  getTemplateList() {
    this._service.getTemplateList(this.folderId, this.page).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isTemplateLoading = false
        this.templateList = this.templateList.concat(res['data']['data'])
        this.pageTitle = res['data']['folder_name']
        this.last_page = res['data']['meta'].last_page
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  getOwnTemplates() {
    let type = this.folderType === 'saved' ? 'own' : 'store'
    this._service.getOwnTemplates(type, this.page).subscribe({
      next: (res) => {
        this.isTemplateLoading = false
        this.templateList = this.templateList.concat(res['data']['data'])
        this.last_page = res['data']['meta'].last_page
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  getSearchTemplateList() {
    this.templateList = []
    this.isTemplateLoading = true
    this._service.searchFolderTemplate(this.folderId, this.searchString).subscribe({
      next: (res) => {
        this.templateList = res['data']['data']
        this.isTemplateLoading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  getSavedSearchFolderList() {
    this.isTemplateLoading = true
    let type = this.folderType === 'saved' ? 'own' : 'store'
    this._service.searchSavedTemplateList(type, this.searchString).subscribe({
      next: (res) => {
        this.templateList = res['data']['data']
        this.isTemplateLoading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  onInputClicked() {
    this.isInputfieldClicked = true
  }

  search(searchInput: string) {
    this.isSearchClicked = true;
    this.isInputfieldClicked = false
    this.page = 1
    this.searchString = searchInput;
    if (searchInput !== '' && searchInput.trim().length !== 0) {
      if (this.folderType === 'templates') {
        this.getSearchTemplateList()
      }
      if (this.folderType === 'saved' || this.folderType === 'assigned') {
        this.getSavedSearchFolderList()
      }
    }
  }

  closeSearch() {
    this.searchString = '';
    this.isTemplateLoading = true;
    this.searchBox.nativeElement.value = null;
    this.page = 1
    this.templateList = []
    this.initComp()
  }

  onImageload(event: Event) {
    this.imageLoaded = true;
  }

  loadMore(event) {
    if (this.page === this.last_page) {
      event.target.complete()
      return;
    }
    this.page++
    this.loadMoreTemplates()
    event.target.complete()
  }

  private loadMoreTemplates() {
    this.initComp()
  }

  onTemplateSelect(list : TemplateData) {

    if(list.type === 'jatak') {
      this._route.navigate(['/tabs/jatak/template/' + list.id], {queryParams: {origin: 'templates'},replaceUrl : true}).then()
    }
    else {
      this._route.navigate(['/tabs/posts/template/regular/' + list.id], {queryParams: {origin: 'templates'},replaceUrl : true}).then()
    }
  }

  handleError(err: any) {
    this._errorHandler.handleError(err, '')
  }

}
