import {Injectable} from '@angular/core';
import {Storage} from '@capacitor/storage';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {UserDetails} from 'app/shared/interface';

import {AuthStateService} from './auth-state.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private apiService: ApiService) {
  }

  // async getUserDetail(): Promise<UserDetails> {
  //   const url = `/user?kardexId=${AuthStateService.authUser.getValue().code}`;
  //   return this.apiService.get(url).pipe(
  //     // eslint-disable-next-line
  //     map(res => res['userDetails'])
  //   ).toPromise();
  // }

    async getUserDetail(): Promise<UserDetails> {
    // const url = `/user?kardexId=${AuthStateService.authUser.getValue().code}`;
    const url = `/user`;
    return this.apiService.get(url).pipe(
      // eslint-disable-next-line
      map(res => res)
    ).toPromise();
  }

   getTimeZones(){
    const  url = '/timezones'
     return this.apiService.get(url).pipe(
       map(res => res['data'])
     )
   }

   updateTimeZone(zone){
    const url = `/user/update-timezone?timezone=${zone}`
     return this.apiService.post(url,{})
   }

  async logout(): Promise<void> {
    //TODO: fix logoout process

    // let language = await Storage.get({key: 'lang'});
    // await Storage.clear();
    // return Storage.set({key: 'lang', value: language.value}); // Maintain Current Language
    const url = `/logout`;
    return this.apiService.posts(url).pipe(
      // eslint-disable-next-line
      map(res => res.success)
    ).toPromise();


  }

  removeUserDetails(): Promise<void> {
    return Storage.clear();
  }

}
