/**
 * MD Action Sheet Enter Animation
 */
import {createAnimation} from '@ionic/angular';
import {Animation} from '@ionic/core';

export const popoverEnterAnimation = (baseEl: HTMLElement, ev?: Event): Animation => {
  const shadowBaseEl = baseEl.shadowRoot;
  const contentEl = shadowBaseEl.querySelector('.popover-content') as HTMLElement;
  const contentDimentions = contentEl.getBoundingClientRect();
  const contentWidth = contentDimentions.width;

  const bodyWidth = (baseEl.ownerDocument as any).defaultView.innerWidth;

  const targetLeft = bodyWidth / 2;
  const targetWidth = 0;

  const arrowEl = shadowBaseEl.querySelector('.popover-arrow') as HTMLElement;
  arrowEl.style.display = 'none';

  const popoverCSS: { left: any } = {
    left: targetLeft + targetWidth / 2 - contentWidth / 2
  };

  contentEl.style.left = popoverCSS.left + 'px';
  contentEl.style.opacity = "1";

  const baseAnimation = createAnimation();
  const backdropAnimation = createAnimation();
  const wrapperAnimation = createAnimation();

  backdropAnimation
    .addElement(shadowBaseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
    .beforeStyles({
      'pointer-events': 'none'
    })
    .afterClearStyles(['pointer-events']);

  wrapperAnimation
    .addElement(shadowBaseEl.querySelector('.popover-content')!)
    .fromTo('transform', 'translateY(100%)', 'translateY(0%)');

  const popoverWrapper = shadowBaseEl.querySelector('.popover-wrapper') as HTMLElement;
  popoverWrapper.style.opacity = '1';

  return baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(.36,.66,.04,1)')
    .duration(400)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

export const popoverLeaveAnimation = (baseEl: HTMLElement): Animation => {
  const baseAnimation = createAnimation();
  const backdropAnimation = createAnimation();
  const wrapperAnimation = createAnimation();


  const shadowBaseEl = baseEl.shadowRoot;

  backdropAnimation
    .addElement(shadowBaseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', 'var(--backdrop-opacity)', 0);

  wrapperAnimation
    .addElement(shadowBaseEl.querySelector('.popover-content')!)
    .fromTo('transform', 'translateY(0%)', 'translateY(100%)');

  return baseAnimation
    .addElement(baseEl)
    .easing('ease')
    .duration(500)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

