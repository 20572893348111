import {Injectable} from '@angular/core';
import {SegmentedMessage} from "sms-segments-calculator";
import emojiRegex from "emoji-regex";

const regex = emojiRegex();

@Injectable({
  providedIn: 'root',
})

export class HelperService {
  constructor() {
  }

  static preventInvalidNumbers(event: any): void {
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);
    if (keyCode === 66 || keyCode === 65 || keyCode === 84) {
      return;
    }
    const regex = /^[0-9.+,]+$/;
    if (keyCode === 118) {
      return;
    }
    if (!regex.test(keyPressed)) {
      event.preventDefault();
    }
  }


  static preventInvalidQuantity(event: any): void {
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);
    if (keyCode === 66 || keyCode === 65) {
      return;
    }
    const regex = /^[0-9]+$/;
    if (keyCode === 118) {
      return;
    }
    if (!regex.test(keyPressed)) {
      event.preventDefault();
    }
  }

  static unicodeToRendered(text) {
    return text.replace(/\\u(\w{4})/g, (match, hex) => String.fromCharCode(parseInt(hex, 16)));
  }


  static renderedToUnicode(text): any {
    // @ts-ignore
    return Array.from(text).map(char => "\\u" + char.charCodeAt(0).toString(16).padStart(4, "0")).join("");
  }

  static parseTextForEmoji(message) {
    return message.replace(/\r\n/g, "\n").replace(regex, '');
  }

  private SmsCounter() {

  }


  static smsParser(message: string) {
    message = this.parseTextForEmoji(message)

    let segmentedMessage = new SegmentedMessage(message, 'auto', true);
    let converted = segmentedMessage.graphemes.join("");
    let nonGsm = segmentedMessage.getNonGsmCharacters();

    if (nonGsm.length > 0) {
      nonGsm.forEach((i) => {
        // @ts-ignore
        converted = converted.replace(`${i}`, '')
        // converted = converted.replace('\n', '');
      })
    }

    segmentedMessage = new SegmentedMessage(converted, 'GSM-7', true);

    return {
      'text': converted,
      'length': segmentedMessage.numberOfCharacters,
      'parts': segmentedMessage.segments.length
    }
  }

}
