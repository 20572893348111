// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.result-info-wrapper {
  width: 50px;
  height: 8px;
}

.result-item-icon {
  width: 20px;
}

.hr {
  border-bottom: 1px solid #C5C5C7;
}

.item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #C5C5C7;
}

.img-skeleton {
  width: 47px;
  height: 47px;
  margin-right: 10px;
  border-radius: 8px;
}

.item-info-wrapper {
  width: 50px;
}

.item-info-title {
  width: 150px;
}

.item-icon {
  height: 35px;
  width: 30px;
}

.log-skeleton {
  margin-top: 20px;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/search-template-skeleton/search-template-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,gCAAA;AAIF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gCAAA;AAKF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAGF;;AADA;EACE,WAAA;AAIF;;AAFA;EACE,YAAA;AAKF;;AAHA;EACE,YAAA;EACA,WAAA;AAMF;;AAJA;EACE,gBAAA;EACA,kBAAA;AAOF","sourcesContent":[".result-wrapper{\n  display: flex;\n  justify-content: space-between;\n  padding:15px 20px;\n}\n.result-info-wrapper{\n  width:50px;\n  height:8px;\n}\n.result-item-icon{\n  width:20px;\n}\n.hr{\n  border-bottom: 1px solid #C5C5C7;\n}\n.item-wrapper{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding:14px 20px;\n  border-bottom: 1px solid #C5C5C7;\n  //border:1px solid #C5C5C7;\n  //border-radius: 8px;\n  //margin-top:10px;\n}\n.img-skeleton{\n  width:47px;\n  height:47px;\n  margin-right:10px;\n  border-radius: 8px;\n}\n.item-info-wrapper{\n  width: 50px;\n}\n.item-info-title{\n  width: 150px;\n}\n.item-icon{\n  height:35px;\n  width:30px;\n}\n.log-skeleton{\n  margin-top:20px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
