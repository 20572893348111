import {Injectable} from '@angular/core';
import { Storage } from '@capacitor/storage';
import {ApiService} from 'app/core/services';
import {Login} from 'app/pages/authentication/interface';
import { PasswordReset } from "app/pages/authentication/interface";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(private apiService: ApiService) {
  }

  login(params: Login): Promise<any> {
    return this.apiService.post('/login', ApiService.createFormData(params)).toPromise();
  }


  resetPassword(params : PasswordReset) : Promise<any> {
    return this.apiService.post('/change-password', ApiService.createFormData(params)).toPromise()
  }

  async getLanguage():Promise<any> {
    const lang = await Storage.get({key: 'lang'});
    return this.apiService.get(`/user/update-locale?locale=${lang.value.toUpperCase()}`).toPromise();
  }
}
