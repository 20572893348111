import {Injectable} from '@angular/core';
import {GetResult, Storage} from '@capacitor/storage';
import {UserDetails} from '../../shared/interface';

@Injectable()
export class TokenService {
  static isPremium: boolean = false;

  /** Handles all the localstorge stuffs**/

  static async setSortId(id){
    await Storage.set({key : 'sortId' ,value : id.toString() } )
  }

  static async getSortId() : Promise<any> {
    return await Storage.get({key: 'sortId'});
  }

  static async setRange(type){
    await Storage.set({key : 'range' ,value : type.toString() } )
  }

  static async getRange() : Promise<any> {
    return await Storage.get({key: 'range'});
  }

  static async setClubNo(id: number) {
    await Storage.set({key: 'clubNo', value: id.toString()});
  }

  static async getClubNo(): Promise<GetResult> {
    return await Storage.get({key: 'clubNo'});
  }

  static async setClubType(id: number) {
    await Storage.set({key: 'clubType', value: id.toString()});
  }

  static async getClubType(): Promise<GetResult> {
    return await Storage.get({key: 'clubType'});
  }

  static async getKlippId(): Promise<GetResult> {
    return await Storage.get({key: 'klippId'});
  }

  static async setClubId(id: number){
    await Storage.set({key: 'clubId', value: id.toString()});
  }

  static async getClubId(): Promise<GetResult> {
    return await Storage.get({key: 'clubId'});
  }

  static async setUserCode(usercode: string) {
    await Storage.set({key: 'coopcode', value: usercode});
  }

  static async getUserCode() {
    const coopCode = await Storage.get({key: 'coopcode'});
    return coopCode.value;
  }

  static async getUserType(): Promise<GetResult> {
    return await Storage.get({key: '_c_u_t_'});
  }

  static async getUserDetail():Promise<UserDetails> {
    const userDetail = await Storage.get({key: '_c_u_d_'});
    return JSON.parse(userDetail.value);
  }

  static async getUserDetailLocal():Promise<UserDetails> {
    const userDetail = await Storage.get({key: '_c_u_d_'});
    return JSON.parse(userDetail.value);
  }

  static usingDefaultLogin() {
    Storage.set({key: '_c_l_t_', value: 'true'});
  }

  static async getToken(): Promise<string> {
    const loginDetails = await this.getLoginDetails();
    return loginDetails?.access_token;
  }

  static async setUserDetail(userDetail) {
    await Storage.set({key: '_c_u_d_', value :JSON.stringify(userDetail)});
  }

  static async setLoginDetails(loginDetails) {
    await Storage.set({key: '_c_l_d_', value: JSON.stringify(loginDetails)});
  }

  static async clearStorage() {

    const keystoRemove = [
    { key : '_c_u_d_' },
    { key : '_c_l_d_'  }
    ]
    for(const key of keystoRemove){
      await Storage.remove(key)
    }
    // await  Storage.remove({key : '_c_u_d_'})
  }

  static async getLoginDetails() {
    const loginDetails = await Storage.get({key: '_c_l_d_'});
    return JSON.parse(loginDetails.value);
  }

  static async isBookletUser(): Promise<boolean> {
    const userDetail = await this.getUserDetail();
    // return (userDetail && userDetail?.quicksale_booklet);
    return true
  }

  static async isFacebookConnected(): Promise<boolean> {
    const userDetail = await this.getUserDetail();
    // i change this becuase key is change isFacebook to facebook
    return (userDetail && userDetail?.facebook?.connected);
  }


  static async isPremiumUser(): Promise<boolean> {
    const userDetail = await this.getUserDetail();
    // const userDetail = true
    // this.isPremium = userDetail && userDetail?.facebook_jatak//this is where it is setting Premium
    this.isPremium = true//this is where it is setting Premium
    // return (userDetail && userDetail?.facebook_jatak);
    return true;
  }

  static async isClubSet(): Promise<boolean> {
    const clubId = await Storage.get({key: 'clubId'});
    return (clubId && clubId.value !== '');
  }

  static async isClubTypeSet(): Promise<boolean> {
    const clubType = await Storage.get({key: 'clubType'});
    return (clubType && clubType.value !== '');
  }

  static async isKlippClubSet(): Promise<boolean> {
    const klippId = await Storage.get({key: 'klippId'});
    return (klippId && klippId.value !== '');
  }

  static async isTokenActive(tokenof?: string): Promise<boolean> {
    const currentTime = Math.floor(Date.now() / 1000);
    if (tokenof === 'facebook') {
      const fbAccess = await Storage.get({key: '_fbAccess_c'});
      const fbExpiration = await Storage.get({key: '_fbExpiration_c'});
      return (
        fbAccess &&
        fbExpiration &&
        fbAccess.value !== '' &&
        fbExpiration.value !== '' &&
        (parseInt(fbExpiration.value, 10) - (60 * 60 * 24)) > currentTime
      );
    };
    const coopToken = await this.getToken();
    const loginDetails = await this.getLoginDetails();
    const coopexpiration = loginDetails?.expiration;
    return (
      coopToken &&
      coopexpiration &&
      coopexpiration !== '' &&
      coopToken !== '' &&
      (parseInt(coopexpiration, 10) - (60 * 60 * 24)) > currentTime
    );
  }

}
