import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';

import {ApiService, AuthStateService, LangService} from "app/core/services";
import {LanguagePopoverComponent} from "app/shared/components";

@Component({
  selector: 'app-language-translator',
  templateUrl: './language-translator.component.html',
  styleUrls: ['./language-translator.component.scss'],
})
export class LanguageTranslatorComponent implements OnInit, OnChanges {
  @Input() isAuthenticated: boolean = false;
  @Input() passwordReset: boolean = false;
  @Output() lang = new EventEmitter<any>();
  currentLanguage = 'da';
  stateLanguage = 'da';

  constructor(
    private popoverCtrl: PopoverController,
    private _translate: TranslateService,
    private apiService: ApiService
  ) {
  }

  async ngOnInit() {
    await this.getUserLanguage()
    this.isAuthenticated = AuthStateService.isTokenActive();
    // this.lang.emit(this.currentLanguage)
  }


  ngOnChanges(changes: SimpleChanges): void {
    // this.isAuthenticated = changes.isAuthenticated.currentValue;
  }


  async onLanguageSelect(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: LanguagePopoverComponent,
      cssClass: 'language-popover',
      event: ev,
      translucent: true,
      animated: true,
    });
    await popover.present();
    return await popover.onDidDismiss().then((data: any) => {
      if (data && data?.data) {
        this.changeLanguage(data?.data);
      }
    });
  }

  changeLanguage(lang: string): void {
    this.lang.emit(lang)
    const language = lang.toUpperCase();
    if (this.isAuthenticated && !this.passwordReset) {
      this.apiService
        .get(`/user/update-locale?locale=${language}`)
        .subscribe((res: any) => {
          if (res.success) {
            this.languageSetpup(lang)
          }
        });
    } else {
      this.languageSetpup(lang)
    }
  }

  languageSetpup(lang) {
    this.currentLanguage = lang;
    LangService.switchLanguage(lang);
    this._translate.use(lang);
    moment.locale(lang);
  }

  async getUserLanguage() {
    this._translate.setDefaultLang(this.stateLanguage);
    this.currentLanguage = await LangService.getCurrentLang();
    this._translate.use(this.currentLanguage.toLowerCase());
    moment.locale(this.currentLanguage);
  }
}
