import {State, Action, StateContext, Selector} from "@ngxs/store";
import {Injectable} from "@angular/core";
import { FormAction } from "../actions/form.action";


@State<any>({
  name: "FormStates",
  defaults: {
    data: null
  }
})

export class FormStates {
  @Selector()
  static getData(state: any) {
    return state.data
  }

  @Action(FormAction.SetFormValue)
  setData(ctx: StateContext<any>, action: FormAction.SetFormValue) {
    ctx.patchState({data: action.data})
  }


  @Action(FormAction.ClearFormValue)
  clearData(ctx: StateContext<any>) {
    const defaults = {
      data: null
    }
    ctx.setState(defaults)
  }

}
