import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {MbscModule} from '@mobiscroll/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from 'app/core/core.module';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import {SharedModule} from 'app/shared/shared.module';
import {navigationAnimation} from 'app/shared/animations';

@NgModule({
    declarations: [AppComponent],
    imports: [
        MbscModule,
        FormsModule,
        BrowserModule,
        NgChartsModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            navAnimation: navigationAnimation,
          innerHTMLTemplatesEnabled: true

        }),
        AppRoutingModule,
        CoreModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: NgChartsConfiguration, useValue: { generateColors: false }}, AndroidPermissions],
    bootstrap: [AppComponent],
})
export class AppModule {
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app/', '.json');
}
