import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {FacebookLoginUrl, FaceBookPage, QuickCoopFacebookData} from 'app/shared/interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TokenService} from './token.service';
import {ToastService} from './toast.service';
import {TranslateService} from '@ngx-translate/core';
import { FacebookEventsService } from './facebook-events.service';
import { ModalController } from '@ionic/angular';
import {FacebookConnectModalComponent} from "../../shared/components";
import {Graph, Statistics, TotalAnalyticsData} from 'app/pages/statistik/interface';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(private apiService: ApiService, private toastService: ToastService, private _translate: TranslateService, private facebookEvents: FacebookEventsService,private modalCtrl: ModalController

  ) {
  }

  async isConnectedToFb(): Promise<boolean> {
    const isFbConnected = await TokenService.isFacebookConnected();
    if (!isFbConnected) {
     this.facebookEvents.connectFacebook();
     const modal = await this.modalCtrl.create({
       component: FacebookConnectModalComponent,
       cssClass: 'fb-connect'
     });
     await modal.present();
    }
    return isFbConnected;
  }

  async ConnectedToFb(): Promise<boolean> {
    const res=this.apiService.get(`/facebook/check-token`).toPromise();
    return res.then(data =>data.connected);
  }

  async getLoginUrl(): Promise<string> {
    return this.apiService.getFacebookLoginUrl()
      .pipe(map((res: FacebookLoginUrl) => res.fb_login_url)).toPromise();
  }

  async getPageList(token: string): Promise<FaceBookPage[]> {
    return this.apiService.getFacebookPageList(token).toPromise();
  }

  async getPagination(url: string): Promise<FaceBookPage[]> {
    return this.apiService.getFacebookPagesPaginationList(url).toPromise();
  }

  async extendToken(token: string): Promise<string> {
    return this.apiService.get(`extend-fb-token.php?short_token=${token}`)
      .pipe(map(res => res['access_token'])
      ).toPromise();
  }

  async facebookConnected():Promise<any>{
    return this.apiService.get(`/facebook/check-token`).toPromise();
  }

  connectFacebookPage(name,id,token): Promise<any> {
    return this.apiService.posts(`/facebook/connect?name=${name}&page_id=${id}&token=${token}`).toPromise();
  }

  async updateFbData(facebookPage: QuickCoopFacebookData): Promise<any> {
    const updateData = {
      facebook: facebookPage,
      kardexId: TokenService.getUserCode()
    };
    const res=this.apiService.posts(`/facebook/disconnect`).toPromise();
    return res.then(data =>data.success);
  }

  async getFBAnalytics(): Promise<Graph>{
    const url = `/graph`;
    return this.apiService.get(url).pipe(
      map(res => res['data'])
      ).toPromise();
  }

  async getStatsAnalytics(preset: string): Promise<Statistics>{
    let sortrange = await TokenService.getRange()
     preset = sortrange.value ? sortrange.value : 'today'
    const url = `/stats?preset=${preset}`;
    return this.apiService.get(url).pipe(
      map(res => res['data'])
      ).toPromise();
  }

  async getdefaultStatsAnalytics(): Promise<TotalAnalyticsData>{
    const url = `/stats?preset=today`;
    return this.apiService.get(url).pipe(
      map(res => res['data'])
      ).toPromise();
  }
}


