import {State, Action, StateContext, Selector} from "@ngxs/store";
import {JatakTabAction} from "../actions/jatak-tab.action";

@State<any>({
  name: "JatakTabStates",
  defaults: {
    data: null
  }
})

export class JatakTabStates {
  @Selector()
  static getData(state: any) {
    return state.data
  }

  @Action(JatakTabAction.SetJatakTab)
  setData(ctx: StateContext<any>, action: JatakTabAction.SetJatakTab) {
    ctx.patchState({data: action.tab})
  }
}
