// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 1.5rem;
  color: var(--ion-color-dark);
  font-weight: var(--ion-font-weight-semibold);
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/no-page/no-page.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,4BAAA;EACA,4CAAA;EACA,iBAAA;AACF","sourcesContent":[".no-page {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0 5px;\n  font-size: 1.5rem;\n  color: var(--ion-color-dark);\n  font-weight: var(--ion-font-weight-semibold);\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
