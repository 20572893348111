import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {AuthState} from 'app/shared/interface';
import {TokenService} from './token.service';

@Injectable({
    providedIn: 'root'
})

export class AuthStateService {
    static authUser = new BehaviorSubject<AuthState>({token: null, code: null, expiration: ''});

    constructor() {
    }

    static async getToken() {
        const loginDetails = await TokenService.getLoginDetails();
        return !!loginDetails;
    }

    static isTokenActive(tokenof?: string): boolean {
        const coopToken = AuthStateService.authUser.value.token;
        return (
            coopToken && coopToken !== ''
        );
    }


    static async loadToken() {
        const loginDetails = await TokenService.getLoginDetails();
        const userCode = await TokenService.getUserCode();
        if (loginDetails) {
            AuthStateService.authUser.next({
                token: loginDetails?.token,//
                code: userCode,
                expiration: loginDetails?.expiration
            });
        }
    }

    static onLogout() {
        AuthStateService.authUser.next({token: null, code: null, expiration: ''});
    }
}
