// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail {
  width: 78px;
  height: 78px;
  --border-radius: 8px;
  margin: 16px 16px 18px 16px;
}

ion-skeleton-text.list-date {
  width: 75px;
}
ion-skeleton-text.list-title {
  width: 190px;
}
ion-skeleton-text.list-comment {
  width: 90px;
}
ion-skeleton-text.list-stock {
  width: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/list-skeleton/list-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,2BAAA;AACF;;AAEE;EACE,WAAA;AACJ;AACE;EACE,YAAA;AACJ;AACE;EACE,WAAA;AACJ;AACE;EACE,YAAA;AACJ","sourcesContent":["ion-thumbnail {\n  width: 78px;\n  height: 78px;\n  --border-radius: 8px;\n  margin: 16px 16px 18px 16px;\n}\nion-skeleton-text {\n  &.list-date {\n    width: 75px;\n  }\n  &.list-title {\n    width: 190px;\n  }\n  &.list-comment {\n    width: 90px;\n  }\n  &.list-stock {\n    width: 140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
