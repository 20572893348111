import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name : 'formatNumberPipe'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any): any {
    if(value){
      if (typeof value !== 'string') {
        value = value.toString();
      }
      // delete existing spaces
      while ((value as string).indexOf(' ') !== -1) {
        value = (value as string).replace(' ', '');
      }
      let integerPart: string = value;
      let firstSlice = true;
      const arrayResults: Array<string> = [];
      let finalResult = '';

      const divisor = 2;
      const dividend: number = integerPart.length;
      let remainder = dividend % divisor;
      let quotient = (dividend + remainder) / divisor;
      if(dividend >= 2){
        do {
          if (firstSlice && remainder > 0) {
            // Manage numbers with remainders
            firstSlice = false;
            arrayResults.push(integerPart.slice(0, remainder));
          } else {
            // Slice each part of the number to an array
            firstSlice = false;
            arrayResults.push(integerPart.slice(remainder, remainder + divisor));
            remainder = remainder + divisor;
            quotient--;
          }
          // Continue dividing the number while there are values
        } while (quotient >= 1);

        arrayResults.forEach(part => {
          finalResult += `${part} `;
        });
        // Delete any trailing whitespace
        finalResult = finalResult.trim();
        return finalResult;
      } else {
        return value
      }
    }
    return value
  }

}
