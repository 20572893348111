import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-list-skeleton',
  templateUrl: './template-list-skeleton.component.html',
  styleUrls: ['./template-list-skeleton.component.scss'],
})
export class TemplateListSkeletonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
