import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import {LangService} from 'app/core/services';

@Pipe({
  name: 'quickdate'
})
export class DateFormatPipe implements PipeTransform {
  lang: string;
  timezone: string;

  transform(date: string) {
    this.getDate();
    let timez=this.getTimezone();
    if (this.lang === 'EN') {
      moment.locale('EN');
      if(timez=='(GMT+1)Copenhagen'){
        return moment.tz("Europe/Paris").format('YYYY-MM-DD HH:mm');
      }
      else if(timez=="(GMT+2)Athens)" || timez=="(GMT+2)Istanbul"){
       return  moment.tz("Europe/Istanbul").format('YYYY-MM-DD HH:mm');
      }
      else if(timez=="(GMT+3)Talinn"){
      return moment.tz("Europe/Riga").format('YYYY-MM-DD HH:mm');
      }
      else{
       return moment(date).format('DD.MMMM.YYYY'); 
      }
    }
    moment.locale('da');
    if(timez=='(GMT+1)Copenhagen'){
      return moment.tz("Europe/Paris").format('LL, [kl.] HH:mm');
    }
    else if(timez=="(GMT+2)Athens)" || timez=="(GMT+2)Istanbul"){
     return  moment.tz("Europe/Istanbul").format('LL, [kl.] HH:mm');
    }
    else if(timez=="(GMT+3)Talinn"){
    return moment.tz("Europe/Riga").format('LL, [kl.] HH:mm');
    }
    else{
      return moment(date).format('LL, [kl.] HH:mm');
    }
  }

  getDate() {
    let lang = localStorage.getItem('CapacitorStorage.lang')
    this.lang = lang
    LangService.getLang().then(
      lang => this.lang = lang
    )
  }

  getTimezone(){
    let getzone = localStorage.getItem('CapacitorStorage.timezone')
    this.timezone = getzone; 
    return this.timezone;
  }
}

@Pipe({
  name: 'simpledateonly'
})
export class SimpleDateFormatPipe implements PipeTransform {
  transform(date: string): any {
    moment.locale('da');
    return moment(date).format('ll');
  }
}

@Pipe({
  name: 'datetostring'
})
export class DateToString implements PipeTransform {
  transform(date: Date): string {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}
