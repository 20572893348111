import {Injectable} from '@angular/core';
import {GetResult, Storage} from "@capacitor/storage";

@Injectable()
export class LangService {

  // get current language
  static async getCurrentLang(): Promise<any> {
    let currentLang = await this.getLang();
    if (!currentLang) {
      this.switchLanguage(this.getUserLocale());
      currentLang = this.getUserLocale();
    }
    return currentLang;
  }

  static async getLang(): Promise<string> {
    const lang = await Storage.get({key: 'lang'});
    return lang.value;
  }

  // switch current language
  static switchLanguage(language: string): void {
    Storage.set({key: 'lang', value: language});
  }

  // get default locale based on browser UI settings
  private static getUserLocale(): string {
    const locale = window.navigator.language || window.navigator['userLanguage'];
    const language = locale.split('-')[0];
    if (['EN', 'DA'].includes(language)) {
      return language;
    }
    return 'EN';
  }

}
