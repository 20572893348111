// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translation-ctn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px 0 0;
}
.translation-ctn .language-options {
  cursor: pointer;
}
.translation-ctn .language-options ion-thumbnail {
  --size: 25px;
  position: relative;
}
.translation-ctn .language-options ion-icon {
  margin-left: 5px;
}
.translation-ctn .language-options .language-select-text {
  margin-right: 10px;
  opacity: 0.4;
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/language-translator/language-translator.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,sBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAI;EACE,YAAA;EACA,kBAAA;AAEN;AAAI;EACE,gBAAA;AAEN;AAAI;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;AAEN","sourcesContent":[".translation-ctn {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 10px 16px 0 0;\n  .language-options {\n    cursor: pointer;\n\n    ion-thumbnail {\n      --size: 25px;\n      position: relative;\n    }\n    ion-icon {\n      margin-left: 5px;\n    }\n    .language-select-text {\n      margin-right: 10px;\n      opacity: 0.4;\n      font-size: 0.75rem ;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
