import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
//  import {Notifications} from '@mobiscroll/angular';
@Injectable({
  providedIn: 'root',
})


export class NotificationsService {
  constructor() {
  }
  // static confirm(confirmationMessage: string, okText:string ='Ok', cancelText: string = 'Annuller'):Observable<Boolean> {
  //   return from(
  //     Notifications.confirm({
  //       message: confirmationMessage,
  //       okText: okText,
  //       cancelText: cancelText,
  //     })
  //   );
  // }
}
