// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button[color=tertiary][fill=outline] ion-spinner {
  color: var(--ion-color-tertiary);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/button/button.component.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;AAAJ","sourcesContent":["ion-button[color=tertiary][fill=outline] {\n  ion-spinner {\n    color: var(--ion-color-tertiary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
