import {MbscDatetimeOptions} from '@mobiscroll/angular';
import * as moment from 'moment';

const timeIntervalFormat = (date) => {
  let newDate = date;
  const min  = moment(date).minutes();
  if  (min > 0) {
    const newMin = (Math.ceil(min / 15) * 15) % 60;
    const toBeAddedMin = newMin - min;
    if (newMin === 0) {
      newDate = moment(date).add(1, 'hours').minute(0).second(0);
    }
    if (newMin !== 0) {
      newDate = moment(date).add(toBeAddedMin, 'minutes').second(0);
    }
  }
  return newDate;
}

export const COMMON_DATE_SETTINGS: MbscDatetimeOptions = {
  theme: 'ios',
  returnFormat: 'iso8601',
  dateFormat: 'yy-mm-dd',
  timeFormat: 'H:ii',
  // lang: 'da',
  display: 'center',
  buttons: ['cancel', 'set'],
  // setText: 'Ok',
  /*min: timeIntervalFormat(moment.now())*/
};
