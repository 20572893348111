// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list .list-ctn {
  border-bottom: 1px solid var(--ion-control-border);
}
ion-list ion-list-header {
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: -0.02px;
  font-weight: var(--ion-font-weight-semibold);
  text-align: left;
  border-bottom: 1px solid var(--ion-control-border);
}
ion-list ion-list-header ion-label {
  margin: 10px 0;
}
ion-list ion-item ion-icon {
  margin-right: 12px;
}
ion-list .premium-option {
  position: relative;
}
ion-list .premium-option ion-badge {
  position: absolute;
  z-index: 2;
  top: 13px;
  right: 16px;
  font-size: 0.75rem;
}
ion-list .premium-option.disabled-option ion-item, ion-list .premium-option.disabled-option ion-icon {
  color: #8E8E8E !important;
}

@media screen and (max-width: 480px) {
  ion-list .premium-option ion-badge {
    top: 15px;
    font-size: 0.7rem;
  }
}
ion-icon {
  color: #1C1C1C;
}

@media screen and (max-width: 320px) {
  ion-list .premium-option ion-badge {
    top: 15px;
    left: 170px;
    font-size: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/options-popover/options-popover.component.scss"],"names":[],"mappings":"AAEE;EACE,kDAAA;AADJ;AAIE;EACE,eAAA;EACA,oBAAA;EACA,uBAAA;EACA,4CAAA;EACA,gBAAA;EACA,kDAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAOI;EACE,kBAAA;AALN;AASE;EACE,kBAAA;AAPJ;AASI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AAPN;AAWM;EACE,yBAAA;AATR;;AAeA;EAGM;IACE,SAAA;IACA,iBAAA;EAdN;AACF;AAmBA;EACE,cAAA;AAjBF;;AAoBA;EAGM;IACE,SAAA;IACA,WAAA;IACA,iBAAA;EAnBN;AACF","sourcesContent":["ion-list {\n  //padding-bottom: 54px;\n  .list-ctn {\n    border-bottom: 1px solid var(--ion-control-border);\n  }\n\n  ion-list-header {\n    font-size: 18px;\n    line-height: 21.78px;\n    letter-spacing: -0.02px;\n    font-weight: var(--ion-font-weight-semibold);\n    text-align: left;\n    border-bottom: 1px solid var(--ion-control-border);\n\n    ion-label {\n      margin: 10px 0;\n    }\n  }\n\n  ion-item {\n    ion-icon {\n      margin-right: 12px;\n    }\n  }\n\n  .premium-option {\n    position: relative;\n\n    ion-badge {\n      position: absolute;\n      z-index: 2;\n      top: 13px;\n      right: 16px;\n      font-size: 0.75rem;\n    }\n\n    &.disabled-option {\n      ion-item, ion-icon {\n        color: #8E8E8E !important;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 480px) {\n  ion-list {\n    .premium-option {\n      ion-badge {\n        top: 15px;\n        font-size: 0.7rem;\n      }\n    }\n  }\n}\n\nion-icon {\n  color: #1C1C1C;\n}\n\n@media screen and (max-width: 320px) {\n  ion-list {\n    .premium-option {\n      ion-badge {\n        top: 15px;\n        left: 170px;\n        font-size: 0.5rem;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
