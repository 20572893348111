import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {TabsAction} from "../../../store/actions/tabs.action";
import {FormAction} from "../../../store/actions/form.action";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  // @Input() pageTitle = 'No page title';
  @Input() isBackgroundWhite: boolean = false
  @Input() pageTitle = 'Settings';
  @Input() showBackArrow: boolean = false
  @Input() setting: boolean = false
  @Input() previousUrl = '/tabs/dashboard'
  @Input() isCampaign: boolean = false
  @Input() isTemplateFolder: boolean = false

  constructor(
    private _location: Location,
    private _router: Router,
    private store: Store
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['previousUrl']) {
      this.previousUrl = changes['previousUrl'].currentValue
    }
  }

  ngOnInit() {
  }

  onBack() {
    this.store.dispatch(new FormAction.ClearFormValue())
    if (this.isCampaign) {
      this.store.dispatch(new TabsAction.ClearTabState());
    }
    if (this.isTemplateFolder) {
      this._location.back()
    } else {
      this._router.navigate([this.previousUrl], {replaceUrl: true}).then()

    }
  }


}
