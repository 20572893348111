// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundWhite {
  background: #fbfbfb !important;
  border-bottom: 1px solid #C5C5C7;
}
.backgroundWhite h5 {
  color: #1C1C1C !important;
  margin: 0;
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00D082;
  width: 100%;
  padding: 10px 0;
  border-radius: 0 0 8px 8px;
}
.app-header h5 {
  color: var(--ion-color-primary-contrast);
  margin: 0;
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: -0.02px;
  font-weight: 700;
}

.back-icon {
  position: absolute;
  cursor: pointer;
  left: 10px;
  color: #1C1C1C;
  z-index: 9999;
  font-size: 18px;
}

.header-wrapper {
  max-width: 768px;
  margin: auto;
  width: 100%;
  position: relative;
}

.setting {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.scss"],"names":[],"mappings":"AAAC;EACC,8BAAA;EACA,gCAAA;AACF;AACE;EACE,yBAAA;EACA,SAAA;AACJ;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,0BAAA;AAAF;AAEE;EACE,wCAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;;AAKA;EACE,kBAAA;EACA,eAAA;EAEA,UAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AAHF;;AAMA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAHF;;AAMA;EACE,8BAAA;EACA,+BAAA;AAHF","sourcesContent":[" .backgroundWhite {\n  background: #fbfbfb !important;\n  border-bottom: 1px solid #C5C5C7;\n\n  h5 {\n    color:  #1C1C1C !important;\n    margin: 0;\n  }\n}\n\n.app-header {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #00D082;\n  width: 100%;\n  padding: 10px 0;\n  border-radius: 0 0 8px 8px;\n\n  h5 {\n    color: var(--ion-color-primary-contrast);\n    margin: 0;\n    font-size: 18px;\n    line-height: 21.78px;\n    letter-spacing: -0.02px;\n    font-weight: 700;\n  }\n\n}\n\n.back-icon {\n  position: absolute;\n  cursor: pointer;\n  //top: 15px;\n  left: 10px;\n  color: #1C1C1C;\n  z-index: 9999;\n  font-size: 18px;\n}\n\n.header-wrapper {\n  max-width: 768px;\n  margin: auto;\n  width: 100%;\n  position: relative;\n}\n\n.setting {\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
