export namespace TabsAction {
  export class SetTab {
    static readonly type = "[SetTabState] Set Tab Data"

    constructor(public tab : any) {
    }
  }

  export class ClearTabState {
    static readonly type = "[ClearTabState] Clear Tab Data"
  }
}
