import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AutologinService} from "../../../../core/services/autologin.service";
import {
  AuthStateService,
  ErrorHandlerService,
  LangService,
  ToastService,
  TokenService,
  UserService
} from "../../../../core/services";
import {switchMap, takeWhile} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {error} from "protractor";
import {AuthService} from "../../services";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss'],
})
export class AutoLoginComponent  implements OnInit {
  code: string;
  private subscriptionState = true;
  clubOptions;
  klippClubOptions;
  isProcessing = false;
  isAuthenticated = false;
  loggedInMessage = ''
  userName = ''
  constructor( private _route : ActivatedRoute,
               private _router : Router,
               private _autoLoginService : AutologinService,
               private authService: AuthService,
               private _errorHandler : ErrorHandlerService,
               private _userService : UserService,
               private errorHandlerService: ErrorHandlerService,
               private toastService: ToastService,
               private _translate: TranslateService,
               ) { }

  ngOnInit() {
    this._userService.removeUserDetails();
    this.code =   encodeURIComponent(this._route.snapshot.queryParamMap.get("key")) ;
    let data = {
      code : this.code
    }
    this._autoLoginService.verifyToken(data)
      .then(res => this._receiveLoginToken(res) )
     .catch(err => this.handleError(err) )
  }

  ionViewWillLeave(): void {
    this.subscriptionState = false;
  }

  private async _receiveLoginToken(e) : Promise<any> {
    AuthStateService.authUser.next({
      token : e?.token,
      code : '',
      expiration: e?.expiration
    })
    const {description, status, success, ...loginDetails} = e;
    await TokenService.setLoginDetails(loginDetails);

    if(e.reset){
      let errormessage = this._translate.instant('_change_password_')
      this.toastService.toast(errormessage, 'error').then()
      this._router.navigate(['/login/reset-password']).then()
      this.isProcessing = false
      return  ;
    }

    await this._userService.getUserDetail().then( async (userDetail) => {
      if(userDetail) {
        this.isAuthenticated = true
        await  TokenService.setUserDetail(userDetail)
        LangService.switchLanguage(userDetail.user.locale)
        this.loggedInMessage = this._translate.instant('_logged_in_')
        this.userName = userDetail.user.name
        let message = this.loggedInMessage + '' +this.userName
        await this.toastService.toast(message, 'success')
        this._router.navigate(['/tabs/dashboard']).then()
      }
      else {
        await this.toastService.toast('Ingen brugerdetaljer fundet', 'error')
        this._router.navigate(['/login']).then()
      }
    } ).catch(error => this.handleError(error) )
  }

  async setClubParams(clubType, clubId) {
    await TokenService.setClubType(clubType);
    await TokenService.setClubId(clubId);
  }

  //
  // setLanguage(){
  //     LangService.switchLanguage(res.locale || 'DA');
  // }

  private handleError(error): void {
    this.errorHandlerService.handleError(error || {code: -400}, 'login');
    this.isProcessing = false;
    this._router.navigate(["/login"]).then();// navigate to login page
  }

}
