import {Component, OnInit} from '@angular/core';
import {environment} from 'environments/environment';
import {AuthStateService, ErrorHandlerService, ToastService, TokenService, UserService} from './core/services';
import {filter, mergeMap, takeWhile} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {logging} from "protractor";
import {EMPTY} from "rxjs";
import {setOptions} from '@mobiscroll/angular';


setOptions({
  theme: 'ios',
  themeVariant: 'light',
});


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  passwordReset = false;
  isProcessing = false;

  constructor(private router: Router,
              private toastService: ToastService,
              private _errorHandlerService: ErrorHandlerService,
              private _userService: UserService,) {
    AuthStateService.loadToken().then();
    this.isAuthenticated = AuthStateService.isTokenActive();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(
      e => {
        this.isAuthenticated = AuthStateService.isTokenActive();
      });
  }

  private _checkVerifyURL(event) {
    if (event.url.indexOf("verify") === -1) {
      if (
        this.isAuthenticated &&
        !sessionStorage.getItem("fetchUser")
      ) {
        sessionStorage.setItem("fetchUser", "true");
        return this._userService.getUserDetail();
      }
      return EMPTY;
    }
    return EMPTY;
  }
}
