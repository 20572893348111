import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCursorTracker]'
})
export class CursorTrackerDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const textarea = this.el.nativeElement as HTMLTextAreaElement;
    const cursorPosition = textarea.selectionStart;
    textarea.setAttribute('data-cursor', cursorPosition.toString());
  }
}
