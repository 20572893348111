export namespace FormAction {
  export class SetFormValue {
    static readonly type = "[SetFormValue] Set Form Data"

    constructor(public data :any) {
    }
  }

  export class ClearFormValue {
    static readonly type = "[ClearFormValue] Clear Form Data"
  }
}
