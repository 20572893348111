// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-ctn {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

ion-thumbnail {
  --size: 35px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/language-popover/language-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;AAEF","sourcesContent":[".popover-ctn {\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n}\nion-thumbnail {\n  --size: 35px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
